import { template as template_8d85e2a1b88b4d1c9dc86d8466bdba79 } from "@ember/template-compiler";
const FKText = template_8d85e2a1b88b4d1c9dc86d8466bdba79(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
