import { template as template_160f568cab8f4b96a41b047ad6a92f85 } from "@ember/template-compiler";
const WelcomeHeader = template_160f568cab8f4b96a41b047ad6a92f85(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
